// TODO: In some places this notion of platform is called "deviceType",
// yet in other places device type means just web or mobile or desktop.
// Making these consistent would add clarity.
const getUserPlatform = (): string => {
  const userSettings = JSON.parse(
    localStorage.getItem("user_settings") || "{}",
  );

  if (userSettings.deviceType) {
    return userSettings.deviceType;
  }

  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return "android";
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "ios";
  } else if (/Windows/i.test(userAgent)) {
    return "windows";
  } else if (/Mac/i.test(userAgent)) {
    return "mac";
  } else {
    return "unknown";
  }
};

const getUserEmail = (): string | null => {
  return localStorage.getItem("user_email") || null;
};

const getUserId = (): string | null => {
  return localStorage.getItem("user_id") || null;
};

const gtmUpdateVariables = () => {
  window.dataLayer.push({
    user_platform: getUserPlatform(),
    user_email: getUserEmail(),
    user_id: getUserId(),
  });
};

export enum GTMEvent {
  LandingPageVisited = "WebEvent_LandingPageVisited",
  Registered = "WebEvent_Registered",
  Download = "WebEvent_Download",
  MagicLogin = "WebEvent_MagicLogin",
}

export const gtmTriggerEvent = (eventName: string) => {
  // Always refresh these to their latest values before the event.
  gtmUpdateVariables();

  window.dataLayer.push({
    event: eventName,
  });
};
