import NewDensityLogo from "../../../assets/Density app logo.svg";
import CloseModalIcon from "../../../assets/Close 20 (2).svg";
import DBunny from "../../../assets/dbunny.png";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { analyticsEvent } from "../../../redux/actions/analytics";

interface UpgradeModalProps {
  error: string;
  open: boolean;
  url: string;
  handleClose: () => void;
  price?: string;
  additionalText?: string;
  title?: string;
  image?: any;
}

const UpgradeModal = ({
  error,
  open,
  url,
  handleClose,
  price,
  additionalText,
  title,
  image
}: UpgradeModalProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) return;
    dispatch(analyticsEvent("PAGE_VISIT", "Payment Page", {}));
  }, [open]);

  if (!open) return null;
  return (
    <div
      style={{
        background: "linear-gradient(360deg, #0C0C14 1.73%, #545FE1 111.59%)",
        zIndex: 101
      }}
      className="overflow-y-none fixed top-0 h-screen w-full bg-black md:h-full md:overflow-y-auto"
    >
      <div className="fixed flex w-full items-center justify-between px-[80px] pt-[24px]">
        <img
          src={NewDensityLogo}
          className="h-[36px] w-[117px] object-contain"
          alt=""
        />
        <button
          className="h-[20px] w-[20px] cursor-pointer"
          name="close-modal"
          onClick={handleClose}
        >
          <img src={CloseModalIcon} alt="" />
        </button>
      </div>
      <div className="my-[60px] flex h-full w-full items-center justify-center md:my-[0px]">
        <div
          style={{
            boxShadow: "0px 14px 20px 0px #00000040",
            borderRadius: "8px",
          }}
          className="h-[calc(100vh-60px)] overflow-y-scroll bg-[#F3F3F5] p-[32px] pb-[60px] md:h-auto md:overflow-y-auto md:pb-[32px]"
        >
          <div
            style={{
              boxShadow: "0px 2px 5px 0px #00000040",
            }}
            className="mb-[16px] rounded-[4px] bg-[#FCFCFCCC] px-[32px] py-[28px]"
          >
            <img
              src={image || DBunny}
              className="mx-auto mb-[10px] h-[54px] w-[43px] object-contain"
              alt=""
            />
            <p className="mb-[4px] text-center font-PPRightGrotesk-SpatialBlack text-[24px] leading-[24px] tracking-[0.15px] text-[#020203CC]">
              {title || "Density premium"}
            </p>
            {title &&
              <p className="text-center text-[14px] font-semibold leading-[24px] tracking-[0.1px] text-[#020203CC]">
                {price}
                {additionalText && <br />}
                {additionalText}
              </p>
            }
          </div>
          <iframe
            style={{
              border: "none",
              boxShadow: "0px 2px 5px 0px #00000040",
              borderRadius: "4px",
              width: "100%",
            }}
            className="max-h-[850px] min-h-[850px] md:max-h-[500px] md:min-h-[450px]"
            src={url}
            title={"chargebee-checkout"}
          />
          <Box
            sx={{
              marginBottom: {
                xs: "75px",
                md: "0px",
              },
            }}
          />
        </div>
      </div>
      {error && <Typography>{error}</Typography>}
    </div>
  );
};

export default UpgradeModal;
