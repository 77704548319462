import { useNavigate } from "react-router-dom";
import { ActionElementType, descriptionListItem, InfoCardProps } from "./types";
import { Button, Link, Typography } from "@mui/material";

function InfoCardWithAction({ data }: { data: InfoCardProps }) {
  const { title, description, link, type = ActionElementType.Link, handleElementAction, className, isBold } = data;

  const navigate = useNavigate();
  const handleElementClick = () => {
    if (handleElementAction) {
      handleElementAction();
      return;
    }
    if (link?.url) navigate(link.url);
  };

  const formatDescription = (description: string | Array<descriptionListItem>) => {
    if (typeof description === "string") {
      return description;
    }

    return (
      <ul>
        {description.map((descriptionItem, index) => {
          const hasPrefix = descriptionItem.text.startsWith("-");

          const liClasses = `${hasPrefix ? "list-disc ml-4" : ""} ${descriptionItem.transform === 'strikethrough' ? 'line-through' : ''}`;
          const displayedItem = hasPrefix
            ? descriptionItem.text.substring(1).trim()
            : descriptionItem.text;

          return (
            <li key={index} className={liClasses}>
              {!hasPrefix && <span className="invisible">•..</span>}
              {displayedItem.toString()}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className={`flex w-full flex-col gap-4 rounded-[30px] p-8 py-12 bg-[#FFFFFF0D] ${className}`}>
      <Typography
        className="text-2xl leading-[29.62px] !text-primary-purple"
        sx={{
          fontFamily: isBold ? "PPRightGrotesk-Bold" : "PPRightGrotesk-WideMedium",
          fontSize: "24px",
          fontWeight: 900,
          lineHeight: "29.62px",
          textAlign: "left",
          maxWidth: "32ch",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        className="text-base leading-6 !text-base-gray"
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.0125rem",
          textAlign: "left",
          maxWidth: "55ch",
        }}
      >
        {formatDescription(description)}
      </Typography>
      {link && (
        <ElementFactory
          type={type}
          text={link.text}
          onClick={handleElementClick}
          href={link.url}
        />
      )}
    </div>
  );
}

export default InfoCardWithAction;

interface ElementProps {
  type: ActionElementType;
  text: string;
  onClick?: () => void;
  href?: string;
}

type ElementComponentMap = {
  [key in ActionElementType]: React.FC<{
    text: string;
    onClick?: () => void;
    href?: string;
  }>;
};

// Object mapping element types to React components
const elementComponents: ElementComponentMap = {
  [ActionElementType.Button]: ({ text, onClick }) => (
    <Button
      variant="contained"
      color="primary"
      // className="density-hero-button"
      onClick={onClick}
      sx={{
        width: "100%",
        height: {
          md: 44,
          xs: 36,
        },
        margin: 0,
        padding: {
          xs: "10px 16px",
          md: "15px",
        },

        backgroundColor: "#c1c5dd",
        mb: "0px",
        mt: "18px",
        fontSize: {
          md: "13px",
        },
      }}
    >
      {text}
    </Button>
  ),
  [ActionElementType.Link]: ({ text, href, onClick }) => (
    <Link
      onClick={onClick}
      href={href !== "" ? href : undefined}
      variant="body2"
      className="font-inter text-[14px] leading-[24px] tracking-wide text-left pb-[16px] text-center underline" 
      style={{ color: "#575F9E", textDecorationColor: "#575F9E" }}
      sx={{
        fontFamily: "Inter !important",
        fontSize: "14px !important",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.0125rem",
        textAlign: "left !important",
      }}
      target="_blank"
    >
      {text}
    </Link>
  ),
};

const ElementFactory: React.FC<ElementProps> = ({
  type,
  text,
  onClick,
  href,
}) => {
  const ElementComponent = elementComponents[type];

  if (!ElementComponent) {
    throw new Error(`Unsupported element type: ${type}`);
  }

  return <ElementComponent text={text} onClick={onClick} href={href} />;
};
