import { useLocation, useNavigate } from "react-router-dom";
import UpgradeModal from "../Profile/modal/UpgradeModal";
import { useEffect, useRef } from "react";

interface CheckoutProps {
    url: string;
    price: string;
    additionalText: string;
    title: string;
    isFromExistingUser: boolean;
    isHardware: boolean;
    cancelUrl?: string;
}

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { url, price, additionalText, title, cancelUrl }: CheckoutProps = location.state || {};
    const previousPath = useRef<string | null>(null);


    useEffect(() => {
        if (!location.state?.url) {
            navigate('/');
        }
        previousPath.current = document.referrer;
    }, [location, navigate]);

    const goBack = () => {
        console.log('cancelUrl', cancelUrl);
        if (cancelUrl) {
            navigate(cancelUrl);
        } else {
            if (previousPath.current && !previousPath.current.includes(location.pathname)) {
                navigate(previousPath.current);
            } else {
                navigate('hardware');
            }
        }
    };

    return (
        <div>
            <UpgradeModal
                error=""
                title={title}
                url={url}
                open={true}
                handleClose={goBack}
                price={price}
                additionalText={additionalText} />
        </div>
    );
}

export default Checkout;