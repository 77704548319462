import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography, InputLabel, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { decodeToken, isExpired } from "react-jwt";

import { analyticsEvent } from "../../redux/actions/analytics";
import {
  StyledAuthContainer,
  StyledAuthInputs,
  StyledAuthInputFields,
} from "../../components/micro/micro.styled";
import { api } from "../../api/base";
import { useSnackBar } from "../../context/SnackBar";
import AuthHeader from "../../components/AuthHeader";
import ForgotPasswordModal from "../../components/modals/ForgotPassword";
import { setLoginModalOpen } from "../../redux/actions/app";

const LoginPage = (): JSX.Element => {
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] =
    useState<boolean>(false);

  const openForgotPasswordModal = (): void => {
    setForgotPasswordModalOpen(true);
  };

  const closeForgotPasswordModal = (): void => {
    setForgotPasswordModalOpen(false);
  };

  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState<{ email: string; password: string }>(
    {
      email: "",
      password: "",
    },
  );

  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const validateForm = async (): Promise<void> => {
    if (isValidEmail(userData.email) && isValidPassword(userData.password)) {
      await loginUser();
    }
  };

  const isValidPassword = (password: string): boolean => {
    setShowPasswordError(false);
    if (!password) {
      setShowPasswordError(true);
      setPasswordError("This is a required field.");
      return false;
    }
    return true;
  };

  const isValidEmail = (email: string): boolean => {
    setShowEmailError(false);
    if (!email) {
      setShowEmailError(true);
      setEmailError("This is a required field.");
      return false;
    }
    if (!validateEmailFormat(userData.email)) {
      setShowEmailError(true);
      setEmailError("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const validateEmailFormat = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const loginUser = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post("auth/login", {
        email: userData.email,
        password: userData.password,
        platform: "web",
        sessionId: localStorage.getItem("sessionId"),
        deviceId: localStorage.getItem("device_id"),
      });
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      showSnackBar(
        "Successfully logged in!",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
      navigate("/account");
    } catch (err: any) {
      if (err.response.status === 401) {
        console.log(err.response);
        if (err.response.data === "Password is incorrect") {
          setShowPasswordError(true);
          setPasswordError("Password is incorrect.");
        } else {
          setShowEmailError(true);
          setEmailError("Email is incorrect.");
        }
      } else {
        setShowEmailError(true);
        setEmailError(err?.response?.data?.msg || err?.response?.data);
      }
    }
  };

  useEffect(() => {
    navigate(`/${window.location.search}`);
    dispatch(setLoginModalOpen(true));
    // analyticsEvent("PAGE_VISIT_V1", {
    //   page: "Landing",
    //   section: "Login",
    // })
    // );
  }, []);

  return <></>;
};

export default LoginPage;
