import { Box, Typography } from "@mui/material";
import { StyledAuthButton } from "../micro/micro.styled";

import LaptopTrialMobile from "../../assets/trial-mobile.png";
import LaptopTrial from "../../assets/trial-image.png";
import { StyledFreeTrialWrapper } from "../../pages/LandingPage/landing.styled";
import { useNavigate } from "react-router-dom";

type StartYourFreeTrialProps = {
  width: number;
  getDensity?: () => void;
};

const StartYourFreeTrial = ({ width, getDensity }: StartYourFreeTrialProps) => {
  const navigate = useNavigate();
  return (
    // <StyledFreeTrialWrapper>
    <div className="relative w-full mx-auto flex flex-col lg:flex-row justify-between pt-[50px] rounded-[30px] overflow-hidden bg-gradient-to-b from-[#686FEF] to-[rgba(104,111,239,0)]">
      <div className="flex flex-col gap-[24px] p-[24px]">
        <Typography
          variant="subtitle2"
          className="text-[34px] text-center"
        >
          Give it a try
        </Typography>
        <StyledAuthButton
          variant="contained"
          color="primary"
          onClick={getDensity || (() => navigate("/login"))}
        >
          Download Density
        </StyledAuthButton>
        <a href="/pricing" className="font-inter text-[14px] text-center lg:text-left leading-[24px] tracking-wide !text-primary-purple underline lg:mt-[24px]">
          Pricing
        </a>
      </div>

      <img src={width > 769 ? LaptopTrial : LaptopTrialMobile} alt="" className="lg:w-[600px]"/>
    </div >
  );
};

export default StartYourFreeTrial;
