import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { UserProps } from '../types/auth';
import { api } from '../api/base';
import { useLocation } from 'react-router-dom';

type UserContextActions = {
  plan: {
    planId: string,
    planName: string,
  };
  user: UserProps;
  otp: string;
  isUserLoggedIn: boolean;
  fetchingUserData: boolean;
  isPasswordSet: boolean;
  setPlan: ({ planId, planName }: { planId: string, planName: string }) => void;
  setUser: (user: UserProps) => void;
  clearUser: () => void;
  fetchOtp: () => Promise<void>;
  setOtp: (otp: string) => void;
  setIsPasswordSet: (isPasswordSet: boolean) => void;
};

interface UserContextProviderActions {
  children: React.ReactNode;
}

const UserContext = React.createContext({} as UserContextActions);

const UserProvider: React.FC<UserContextProviderActions> = ({ children }: any) => {
  const [plan, setPlan] = useState<{ planId: string, planName: string }>({
    planId: '',
    planName: '',
  });
  const [user, setUser] = useState<UserProps>({} as UserProps);
  const [otp, setOtp] = useState<string>('');
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(false);
  const [fetchingUserData, setFetchingUserData] = useState<boolean>(true);
  const location = useLocation();

  const fetchOtp = async () => {
    try {
      const response = await api.get('/auth/otp');
      setOtp(response.data.otp);
    } catch (error) {
      console.error('Error fetching OTP:', error);
    }
  };

  const clearUser = () => {
    setUser({} as UserProps);
  };

  useEffect(() => {

    const getProfile = async (): Promise<void> => {
      setFetchingUserData(true);
      try {
        const { data }: AxiosResponse<any, any> = await api.get("auth/profile");
        const passwordIsSet = data?.user?.password !== "";
        localStorage.setItem("user_email", data.user.email);

        setIsUserLoggedIn(passwordIsSet);
        setIsPasswordSet(passwordIsSet);
        setFetchingUserData(false);
        setUser(data.user);

      } catch (err) {
        setIsUserLoggedIn(false);
        setFetchingUserData(false);
      }
      setFetchingUserData(false);
    };

    getProfile();

  }, [location]);

  return (
    <UserContext.Provider value={{ setPlan, plan, user, setUser, otp, fetchOtp, setOtp, clearUser, isUserLoggedIn, fetchingUserData, isPasswordSet, setIsPasswordSet }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextActions => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }

  return context;
};

export { UserProvider, useUserContext };