import { ArrowBackIos } from "@mui/icons-material";

import {
  StyledAuthButton,
  StyledAuthHeader,
  StyledAuthInputs,
} from "../../components/micro/micro.styled";
import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";

import NewDensityLogo from "../../assets/Density app logo.svg";
import HardwareDensity from "../../assets/density-hardware.svg";

import FinalStepImage from "../../assets/Main screen active.png";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { analyticsEvent } from "../../redux/actions/analytics";

const UpgradeSuccessPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isHardware = searchParams.get("plan") === "hardware";
  const backButtonHandler = () => {
    navigate("/onboarding/6?instructions=true");
  };

  useEffect(() => {
    if (!isHardware) {
      dispatch(
        analyticsEvent("PAGE_VISIT", "Confirmation Page", {
          plan: "premium",
        }),
      );
    } else {
      dispatch(
        analyticsEvent("EVENT", "Hardware purchased", {
          page: "Hardware",
        }),
      );
    }
  }, []);

  const handleButtonClick = () => {
    if (searchParams.get("fromDesktop") === "true") {
      window.open("density://", "_blank");
      backButtonHandler();
    } else {
      backButtonHandler();
    }
  };

  const steps = ["1", "2", "3", "4"];

  return (
    <div className="flex h-[100%] w-full flex-col md:justify-between overflow-hidden bg-[#141519] lg:h-full">
      <StyledAuthHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "500px",
            mx: "auto",
            position: "relative",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "0",
            }}
            onClick={backButtonHandler}
          >
            <ArrowBackIos />
          </Box>
          <img
            onClick={() => navigate("/")}
            style={{ width: "175px", height: "54px", objectFit: "contain" }}
            src={NewDensityLogo}
            alt={"Density"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "10px",
            gap: "10px",
            justifyContent: "space-between",
            maxWidth: "500px",
          }}
          mt={2}
          mx={"auto"}
        >
          {steps.map((stepNumber: string) => (
            <Box
              key={stepNumber}
              sx={{
                py: "8px",
                width: width! <= 500 ? "45px" : "106px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  height: "3px",
                  flexShrink: 0,
                  width: width! <= 500 ? "45px" : "106px",
                  borderRadius: "4px",
                  backgroundColor: "#fcfcfc",
                }}
              ></Box>
            </Box>
          ))}
        </Box>
      </StyledAuthHeader>

      <div
        className="flex flex-col lg:flex-row justify-between items-center gap-8 h-[100%]"
      >
        <div className="min-w-max h-[100%]">
          <StyledAuthInputs
            sx={{
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                textAlign={"center"}
                fontSize={"20px"}
                variant={"h5"}
                mb={2}
                fontWeight={500}
                color={"rgba(252, 252, 252, 1)"}
                marginTop={8}
              >
                {isHardware ? "Your order is on the way!" : "You're on Density Premium!"}
              </Typography>
              {isHardware && (
                <Typography
                  fontSize={"12px"}
                  fontWeight={500}
                  textAlign={"center"}
                  variant={"body1"}
                  color={"rgba(252, 252, 252, 0.6)"}
                  maxWidth={275}
                  mx={"auto"}
                  lineHeight={"18px"}
                  letterSpacing={"0.1px"}
                >
                  While you wait, download the Density Desktop app to learn how to edit a set.
                </Typography>)}
            </Box>
            <Box sx={{ mt: "45px" }}>
              <StyledAuthButton
                onClick={handleButtonClick}
                variant={"contained"}
                sx={{
                  mx: 0,
                  mb: 2,
                }}
              >
                {searchParams.get("fromDesktop") === "true"
                  ? "Open Density App"
                  : " Download"}
              </StyledAuthButton>
            </Box>
          </StyledAuthInputs>
        </div>
        <div className="mx-auto">
          {isHardware ? (
            <img
              className="overflow-clip object-contain  -translate-x-[20px] sm-translate-x-[44px] md:-translate-x-[56px]"
              src={HardwareDensity}
              alt="REC-1 Hardware"
            />) : (
            <img
              src={FinalStepImage}
              alt="REC-1 Hardware"
              className=""
            />)}
        </div>
      </div>
    </div>
  );
};

export default UpgradeSuccessPage;
