import { Navigate, useSearchParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";

const ProtectedRoute = ({ children }: any) => {
  const [searchParams] = useSearchParams();
  const { user, isUserLoggedIn, fetchingUserData } = useUserContext();
  if (!isUserLoggedIn && !fetchingUserData) {
    const url = `/${window.location.search}`;
    return <Navigate to={url} />;
  }

  if (searchParams.get("access_token")) {
    return children;
  }

  if (localStorage.getItem("access_token")) {
    return children;
  }

  // url with search params
  const url = `/${window.location.search}`;
  return <Navigate to={url} />;
};

export default ProtectedRoute;
