import { Typography } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import LandingNavbar from "../../components/LandingNavbar";
import {
    StyledDensityHardwareContainer,
    StyledLandingPageWrapper,
} from "./landing.styled";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";
import Footer from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import Decks4 from "../../assets/decks-4.svg";
import NoWatermark from "../../assets/no-watermark.svg";
import Header from "../../components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import HardwareDensity from "../../assets/Hardware-with-shadow.png";
import { useUserContext } from "../../context/UserContext";
import UpgradeModal from "../Profile/modal/UpgradeModal";
import { SinglePlanItem, SubscriptionClass } from "../../types/subscription";
import { useSnackBar } from "../../context/SnackBar";
import ConfirmRenewalModal from "../../components/modals/ConfirmRenewal";

const PricingPage = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [visible] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const [isSubscribed] = useState(false);
    const { user, setPlan, isUserLoggedIn, fetchingUserData, isPasswordSet } = useUserContext();
    const [renewalModalOpen, setRenewalModalOpen] = useState<boolean>(false);

    type upgradeDataType = { open: boolean; url: string; handleCloseModal: () => void; price: string; additionalText: string; };
    const [upgradeData, setUpgradeData] = useState({} as upgradeDataType);
    const [loadingUpgrade, setLoadingUpgrade] = useState(false);
    const [loadingPurchase, setLoadingPurchase] = useState(false);
    const [plans, setPlans] = useState<SinglePlanItem[]>([]);
    const { showSnackBar } = useSnackBar();
    const [subscriptions, setSubscriptions] = useState<SubscriptionClass>(
        {} as SubscriptionClass,
    );

    const getPlans = async (): Promise<void> => {
        try {
            const { data }: AxiosResponse<any, any> =
                await api.get("chargebee/plans");
            setPlans(data);
        } catch (err) { }
    };

    const renewSubscription = async (): Promise<void> => {
        try {
            const { data }: AxiosResponse<any, any> = await api.post(
                "chargebee/renew-subscription",
                {
                    planId: subscriptions.id,
                    trialEnd: subscriptions.trial_end,
                    status: subscriptions.status,
                },
            );
            showSnackBar(
                "Successfully reactivated subscription.",
                "success",
                "center",
                2000,
                false,
                "auto",
                "95px",
            );
            await getSubscriptions();
            setRenewalModalOpen(false);
        } catch (err: any) {
            const { response } = err;
            showSnackBar(response.data.error_msg, "error");
        }
    };

    const handleSubscriptionRenewal = async (): Promise<void> => {
        renewSubscription();
    };

    const getSubscriptions = async (): Promise<void> => {
        try {
            const { data }: AxiosResponse<any, any> = await api.get(
                "chargebee/subscriptions",
            );
            setSubscriptions(data);
            setPlan({ planId: data.id, planName: data.item_price_id });
        } catch (err) { }
    };
    const handleRenewalConfirmation = () => {
        setRenewalModalOpen(true);
    };
    useEffect(() => {

        dispatch(
            analyticsEvent("EVENT", "Pricing Page Visited", {
                page: "Pricing Page",
            }),
        );
        const getPlansAndSubscriptions = async () => {
            await getPlans();
            await getSubscriptions();
        };

        getPlansAndSubscriptions();
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
        }
        return () => {
            document.body.style.overflowY = "scroll";
        };
    }, [isOpen]);

    const upgradeButtonText = () => {
        if (subscriptions.status === "non_renewing") {
            return "Reactivate"
        } else if (subscriptions.status === "cancelled") {
            return "Renew";
        }
        return "Upgrade";
    }

    const disableUpgradeButton = () => {
        if ((loadingUpgrade || isSubscribed) && subscriptions.status === "active" || fetchingUserData) return true;
        return false;
    }

    const buyHardwarePlan = async (): Promise<void> => {
        if (!isUserLoggedIn && !fetchingUserData) {
            getDensity(false, true);
            return;
        }
        try {
            const token = localStorage.getItem('access_token');
            //@ts-ignore
            const decodedToken = decodeToken(token);
            //@ts-ignore
            const email = decodedToken?.email;
            const { data }: AxiosResponse<any, any> = await api.post(
                "chargebee/checkout-hardware",
                {
                    redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware`,
                    email
                }
            );
            navigate(`/account/checkout`, {
                state: {
                    url: data.hosted_page.url,
                    additionalText: "Hardware - $300",
                    title: "REC-1",
                    cancelUrl: "/pricing",
                }
            });
        } catch (err) {
            console.error(err)
        }
        setLoadingPurchase(false)
    };

    const getDensity = (isUpgrade = false, isHardware = false) => {
        if (props.getDensity) {
            props.getDensity();
        } else {
            localStorage.removeItem("landing");

            const ref = searchParams.get("ref");

            if (user?.email && !isPasswordSet) {
                navigate(`/onboarding/5/${ref || ""}${isUpgrade ? "?upgrade=true" : ""}${isHardware ? "?plan=hardware" : ""}`);
                return;
            }

            if (ref) {
                navigate(`/onboarding/0/${ref}${isUpgrade ? "?upgrade=true" : ""}${isHardware ? "?plan=hardware" : ""}`);
            }
            else {
                navigate(`/onboarding/1/density38${isUpgrade ? "?upgrade=true" : ""}${isHardware ? "?plan=hardware" : ""}`);
            }
        }
    };

    const handleDownload = () => {
        dispatch(
            analyticsEvent("EVENT", "Download Density App", {
                "OS Downloaded": "Universal",
                location: "Pricing Page",
            }),
        );

        api
            .post("/auth/download", {
                email: user?.email,
                os: "universal",
            })
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                // console.log(err);
            });
        navigate('/onboarding/6/density38?instructions=true')
    };

    const handleDownloadFree = () => {
        localStorage.setItem("onboarding_in_progress", "true")
        dispatch(
            analyticsEvent("CLICK", "Download Free Button clicked", {
                page: "Pricing Page",
            }),
        );
        if (isUserLoggedIn && !fetchingUserData) {
            handleDownload();
        } else {
            getDensity();
        };
    }

    const handleUpgrade = useCallback(async () => {
        setLoadingUpgrade(true);
        const upgradeButtonTextValue = upgradeButtonText();
        dispatch(
            analyticsEvent("CLICK", `${upgradeButtonTextValue} button clicked`, {
                location: "Pricing Page",
            }),
        );

        if (isUserLoggedIn && !fetchingUserData && upgradeButtonTextValue === "Upgrade") {
            const buyPlan = async (): Promise<void> => {
                try {
                    const { data }: AxiosResponse<any, any> = await api.post(
                        "chargebee/checkout",
                        {
                            item_price_id: "Premium-USD-Monthly",
                            // unit_price: plan.price,
                            redirect_url: `${process.env.REACT_APP_URL}/account${searchParams.get("fromDesktop") ? "?fromDesktop=true" : ""}`,
                        },
                    );
                    const handleCloseModal = () => {
                        setUpgradeData({ ...upgradeData, open: false });
                        setLoadingUpgrade(false);
                        navigate("/onboarding/6");
                    }
                    setUpgradeData({ ...upgradeData, url: data.url, price: "$15/month", open: true, handleCloseModal });
                    setLoadingUpgrade(false);

                } catch (err) { }
            };
            try {
                await buyPlan();
            } catch (err) {
                console.error(err)
                setLoadingUpgrade(false);
            }
            return;
        } else {
            if (isUserLoggedIn && !fetchingUserData && (upgradeButtonTextValue === "Reactivate" || upgradeButtonTextValue === "Renew")) {
                handleRenewalConfirmation();
            } else {
                getDensity(true);
            }
        };
        setLoadingUpgrade(false);
    }, [
        isUserLoggedIn,
        fetchingUserData,
        upgradeButtonText,
        getDensity,
        handleRenewalConfirmation,
    ]
    );

    const handlePurchase = () => {
        setLoadingPurchase(true);
        dispatch(
            analyticsEvent("CLICK", "Purchase button clicked", {
                page: "Pricing Page",
            }),
        );
        buyHardwarePlan()
    };

    return (
        <>
            <StyledLandingPageWrapper className="landing-page-wrapper">
                {(isUserLoggedIn && !fetchingUserData) ? <Header /> :
                    <LandingNavbar visible={visible} ww={width} getDensity={() => { return handlePurchase() }} />}
                <main style={{ maxWidth: 1360, margin: "auto" }}>

                    <StyledDensityHardwareContainer>

                        <div className="mt-[72px] mb-[72px]">
                            <Typography
                                className="density-hero-heading hardware-density-heading"
                                variant="h1"
                                component="h1"
                                sx={{
                                    background:
                                        "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                                    "-webkit-background-clip": {
                                        md: "text",
                                        xs: "text",
                                    },
                                    "-webkit-text-fill-color": {
                                        md: "transparent",
                                        xs: "transparent",
                                    },
                                }}
                            >
                                Pricing
                            </Typography>
                        </div>
                    </StyledDensityHardwareContainer>

                    <section className="">
                        <div className="flex flex-wrap gap-6 ">

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-24px)] box-border flex flex-col justify-between text-center p-[48px] pb-[60px]">
                                <div className="flex flex-row  w-[100%] justify-between pb-[24px]">
                                    <div className="flex flex-col">
                                        <h2
                                            className="font-['PPRightGrotesk-WideMedium'] text-[34px] font-black leading-[27.42px] text-center  whitespace-nowrap !text-primary-purple pb-[8px]"
                                        >
                                            {'Basic'}
                                        </h2>
                                        <p className="font-inter font-sm leading-[24px] tracking-wide text-left text-base !text-primary-purple">
                                            Free
                                        </p>
                                    </div>
                                    <div>
                                        <img src={Decks4} alt="Decks4" className="w-[108px]" />
                                    </div>
                                </div>

                                <div className="font-inter text-sm font-medium leading-6 tracking-[0.0125rem] text-left !text-base-gray pb-6">
                                    <li className="disc-list">Complete use of the <span className="tracking-wide text-left text-center underline cursor-pointer" style={{ color: "#575F9E" }} onClick={() => { navigate('/') }}>Density DAW</span> for free</li>
                                    <div className="pl-6">
                                        <p>– Point & Click to create a set</p>
                                        <p> – Capture a live set by connecting to your decks to your laptop</p>
                                    </div>
                                    <li className="pb-2">Master any Density recording</li>
                                    <p>
                                        Limitation:
                                    </p>
                                    <li className="disc-list">
                                        On export there is an unobtrusive audio watermark that says, “mixed with Density”
                                    </li>
                                </div>
                                <div className="w-[100%]">
                                    <a
                                        href={`${(isUserLoggedIn && !fetchingUserData) ? "https://release.density.one/Density-Installer-Universal-Latest.dmg" : ""}`}
                                        download={false}
                                    >
                                        <StyledAuthButton
                                            onClick={handleDownloadFree}
                                            variant={"outlined"}
                                            className="min-w-[100%]"
                                            disabled={fetchingUserData}
                                            sx={{
                                                m: 0,
                                                minWidth: "100%",
                                                background: "rgba(255, 255, 255, 0.08)",
                                                borderColor: "rgba(252, 252, 252, 0.5)",
                                                letterSpacing: "0.5px",
                                                fontSize: "11px",
                                                color: "rgba(252, 252, 252, 0.6)",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Download Free
                                        </StyledAuthButton>
                                    </a>
                                </div>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[calc(50%-0.5rem)] box-border flex flex-col justify-between text-center p-[48px] pb-[60px]">
                                <div className="flex flex-row  w-[100%] justify-between pb-[24px]">
                                    <div className="flex flex-col">
                                        <h2
                                            className="font-['PPRightGrotesk-WideMedium'] text-[34px] font-black leading-[27.42px] text-center  whitespace-nowrap !text-primary-purple pb-[8px]"
                                        >
                                            {'Premium'}
                                        </h2>
                                        <p className="font-inter font-sm leading-[24px] tracking-wide text-left text-base !text-primary-purple">
                                            $15/mth
                                        </p>
                                    </div>
                                </div>
                                <div className="font-inter text-sm font-medium leading-6 tracking-[0.0125rem] text-left !text-base-gray pb-6">

                                    <p className="pb-[50px]">Removes the audio watermark</p>
                                    <div className="w-[calc(100%+96px)] -mx-[48px]">
                                        <img src={NoWatermark} alt="no-watermark" className="" />
                                    </div>
                                </div>
                                <div className="w-[100%]">

                                    <StyledAuthButton
                                        onClick={handleUpgrade}
                                        variant={"contained"}
                                        color="primary"
                                        className="min-w-[100%]"
                                        disabled={disableUpgradeButton()}
                                        sx={{
                                            minWidth: "100%",
                                            height: {
                                                md: 44,
                                                xs: 36,
                                            },
                                            margin: 0,
                                            padding: {
                                                xs: "10px 16px",
                                                md: "15px",
                                            },
                                            backgroundColor: "#c1c5dd",
                                            mb: "0px",
                                            fontSize: {
                                                md: "13px",
                                            },
                                        }}
                                    >
                                        {upgradeButtonText()}
                                    </StyledAuthButton>
                                </div>
                            </div>

                            <div className="bg-[#FFFFFF0D] rounded-[30px] relative w-[100%] md:w-[100%] box-border flex flex-col-reverse lg:flex-row justify-between text-center p-[48px] pb-[60px]">
                                <div className="flex flex-col w-[100%] lg:w-[50%] justify-between lg:pb-[24px]">
                                    <div className="flex flex-col pb-[24px] ">
                                        <h2
                                            className="font-['PPRightGrotesk-WideMedium'] text-[34px] font-black leading-[29.62px] text-center lg:text-left !text-primary-purple pb-[8px]"
                                        >
                                            {'Club/Festival Hardware'}
                                        </h2>
                                        <p className="font-inter font-sm leading-[24px] tracking-wide text-center lg:text-left text-base !text-primary-purple">
                                            $300 - one time
                                        </p>
                                    </div>

                                    <div className="font-inter text-sm font-medium leading-6 tracking-[0.0125rem] text-center lg:text-left !text-base-gray pb-6 h-[100%] tracking-[0.0125rem]">
                                        <div className="lg:max-w-[55ch] pb-4">
                                            The hardware is designed for clubs / festivals. The free version of the software allows you to manage your recordings and automatically splits sets. You can then send the set to the DJ for mastering.
                                        </div>

                                        <p>
                                            If you choose to buy the hardware for personal use:
                                        </p>
                                        <div className="pl-4">
                                            <li className="disc-list">
                                                You can use the free version of the Density DAW to edit your sets.
                                            </li>
                                            <li className="disc-list">
                                                Upgrade to remove the audio watermark.
                                            </li>
                                        </div>
                                    </div>
                                    <div className="w-[100%] flex lg:justify-start justify-center">

                                        <StyledAuthButton
                                            onClick={handlePurchase}
                                            variant={"contained"}
                                            color="primary"
                                            className="min-w-[100%]"
                                            disabled={loadingPurchase}
                                            sx={{
                                                // minWidth: "100%",
                                                height: {
                                                    md: 44,
                                                    xs: 36,
                                                },
                                                margin: 0,
                                                padding: {
                                                    xs: "10px 16px",
                                                    md: "15px",
                                                },
                                                backgroundColor: "#c1c5dd",
                                                mb: "0px",
                                                fontSize: {
                                                    md: "13px",
                                                },
                                            }}
                                        >
                                            purchase
                                        </StyledAuthButton>
                                    </div>
                                </div>
                                <div className=" w-[100%] lg:w-[50%] flex justify-center  pb-[24px] lg:pb-0">
                                    <img
                                        className="hardware-density-image w-[380px] lg:min-w-[420px] -translate-x-[20px] md:-translate-x-[40px] lg:-translate-x-[24px]"
                                        src={HardwareDensity}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main >
                {upgradeData.open && upgradeData.url &&
                    <UpgradeModal
                        error={""}
                        open={upgradeData.open}
                        url={upgradeData.url}
                        handleClose={() => setUpgradeData({ ...upgradeData, open: false })}
                        price={upgradeData.price}
                        additionalText={upgradeData.additionalText}
                    />
                }

                <ConfirmRenewalModal
                    open={renewalModalOpen}
                    handleClose={() => setRenewalModalOpen(false)}
                    handleRenewal={handleSubscriptionRenewal}
                    subscriptionPrice={`$${subscriptions?.unit_price / 100}`}
                    action={
                        typeof subscriptions?.next_billing_at === "undefined"
                            ? subscriptions?.status === "non_renewing"
                                ? `Reactivate`
                                : `Renew`
                            : "Cancel"
                    }
                />
            </StyledLandingPageWrapper >

        </>
    );
};

export default PricingPage;
