import { Button, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import LandingNavbar from "../../components/LandingNavbar";

import HardwareDensity from "../../assets/Hardware-with-shadow.png";
import WithDensityHardware from "../../assets/with-density-hardware.svg";
import LaptopRecording from "../../assets/laptop-recording.svg";

import {
  StyledDensityHardwareContainer,
  StyledLandingBoldParagraph,
  StyledLandingPageWrapper,
  StyledLandingVideoWrapper,
} from "./landing.styled";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";
import Footer from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import { InfoCardWithAction, InfoPanel } from "../../components/Info";
import TEXTS from "./text";
import ModalVideo from "react-modal-video";
import NewCoverImage from "../../assets/new-cover-image.png";
import FindYourTracks from "../../assets/find-your-tracks.svg";
import RemoveUnreleasedTrack from "../../assets/remove-unreleased-tracks.svg";
import NeverLoseASetAgain from "../../assets/never-lose-a-set.png";
import EnsureConsistentLoudness from "../../assets/ensure-loudness.svg";
import RiderFriendly from "../../assets/rider-friendly.svg";
import PlayIcon from "../../assets/Pause Controls.svg";
import FullSetup from "../../assets/full-setup.svg";
import EditRecordings from "../../assets/edit-recordings-density-daw.png";
import EditRecordingsSmall from "../../assets/edit-recordings-density-daw-small.png";
import InfoShowcase from "../../components/Info/InfoShowcase";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import Header from "../../components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import { useUserContext } from "../../context/UserContext";

const LandingPageAlt = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
  const [loading, setLoading] = useState(!contentLoaded);
  const videoRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { isUserLoggedIn, fetchingUserData } = useUserContext();

  useEffect(() => {
    dispatch(
      analyticsEvent("EVENT", "Hardware Page Visited", {
        page: "Hardware Page",
      }),
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, true);

    if (loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("content-loaded", "true");
      }, 2500);
    }

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [dispatch, loading, prevScrollPos]);

  const buyHardware = async (): Promise<void> => {
    setLoading(true);

    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          item_price_id: "PremiumHardware-USD-Yearly",
          redirect_url: `${process.env.REACT_APP_URL}/onboarding/6/?plan=hardware`,
        },
      );
    } catch (err) {
      console.error(err)
      setLoading(false);
    }
  };
  const buyHardwarePlan = async (): Promise<void> => {
    if (!isUserLoggedIn && !fetchingUserData) {
      localStorage.clear();
      navigate("/onboarding/1/density38?plan=hardware", { state: { from: 'hardware' } });
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      //@ts-ignore
      const decodedToken = decodeToken(token);
      //@ts-ignore
      const email = decodedToken?.email;
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware&instructions=true`,
          email
        },
      );
      navigate(`/account/checkout`, {
        state: {
          url: data.hosted_page.url,
          additionalText: "Hardware - $300",
          title: "REC-1",
          cancelUrl: "/hardware"
        }
      });
    } catch (err) {
      console.error(err)
      setLoading(false);
    }
  };

  const handlePurchaseAction = () => {
    dispatch(
      analyticsEvent("INPUT", "Hardware - Get Hardware Clicked", {
        page: "Hardware",
      }),
    );
    buyHardwarePlan()
    // navigate("/onboarding/1?plan=hardware", { state: { from: 'hardware' } });
  };

  const getDensity = () => {
    const flow = props.getDensity ? "invited" : "primary"; // Determine the flow based on the URL

    if (props.getDensity) {
      props.getDensity();
    } else {
      localStorage.removeItem("landing");
      dispatch(
        analyticsEvent("CLICK", "Get Density Clicked", {
          page: "Landing Page",
          flow: flow, // Use the determined flow
        }),
      );

      const ref = searchParams.get("ref");

      if (ref) {
        navigate(`/onboarding/0/${ref}`);
      }
      else {
        navigate(`/onboarding/1/density38`);
      }
    }
  };

  const handleLearnMore = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleOpenWatchHowItWorksLink = () => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setOpen(true)
  }

  const getPurchaseHardwareCardInfo = () => {
    const info = { ...TEXTS.SECOND_INFO_CARD, title: '$300', description: [] };
    return info
  }

  return (
    <>
      <StyledLandingPageWrapper className="landing-page-wrapper">
        {(isUserLoggedIn && !fetchingUserData) ? <Header /> :
          <LandingNavbar visible={visible} ww={width} getDensity={() => { return handlePurchaseAction() }} />}
        <main style={{ maxWidth: 1360, margin: "auto" }} className="p-[1.27rem]">
          <StyledDensityHardwareContainer>
            <img
              className="hardware-density-image  -translate-x-[20px] sm:-translate-x-[44px] md:-translate-x-[56px] pb-3"
              src={HardwareDensity}
              alt=""
            />
            <Typography
              className="density-hero-heading hardware-density-heading"
              variant="h1"
              component="h1"
              sx={{
                background:
                  "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              The end of <br />
              “it didn’t record”
            </Typography>
          </StyledDensityHardwareContainer>

          <section className="mt-[38px] md:mt-24">
            <div className="flex flex-col lg:flex-row gap-[26px] justify-between">
              <InfoCardWithAction
                data={{ ...TEXTS.FIRST_INFO_CARD, handleElementAction: handleOpenWatchHowItWorksLink, className: "flex-1 ", isBold: true }}
              ></InfoCardWithAction>
              <div className={`flex flex-col sm:flex-row items-center justify-center text-center text-primary-purple m-w-max px-[28px] pt-[48px] px-[59px] box-border bg-[#FFFFFF0D] rounded-[30px] w-[100%] `} style={{ flex: 2 }}>
                <div className="flex flex-col items-center justify-center text-center mx-auto h-[100%] w-[100%]">
                  <h2
                    className="font-['PPRightGrotesk-Bold'] text-[18px] font-black leading-tight text-center  whitespace-nowrap !text-primary-purple pb-[16px]"
                  >
                    {'Density hardware'}
                  </h2>
                  <p className="font-inter text-[14px] leading-[24px] tracking-wide text-left !text-base-gray pb-[64px]">
                    $300
                  </p>
                  <div className="w-[100%]">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseAction}
                      sx={{
                        width: "100%",
                        height: {
                          md: 44,
                          xs: 36,
                        },
                        margin: 0,
                        padding: {
                          xs: "10px 16px",
                          md: "15px",
                        },
                        backgroundColor: "#c1c5dd",
                        mb: "32px",
                        fontSize: {
                          md: "13px",
                        },
                      }}
                    >
                      Purchase
                    </Button>
                  </div>
                  <img src={WithDensityHardware} alt="with density hardware" className="w-[186px]" />

                </div>

                <div className="flex flex-row sm:flex-col items-center w-[100%] sm:w-[27px] sm:h-[100%] mx-[45px] pt-[41px]">
                  <div className="flex flex-row sm:flex-col h-[1px] sm:h-[120px]  w-[100%] sm:w-[1px] bg-[#FFFFFF] sm:margin-auto opacity-10" />
                  <p className=" m-4 font-inter text-[14px] leading-[32px] tracking-wide text-left !text-base-gray h-[32px] my-[10px] ">OR</p>
                  <div className="flex flex-row sm:flex-col h-[1px] sm:h-[120px]  w-[100%] sm:w-[1px] bg-[#FFFFFF] sm:margin-auto opacity-10" />
                </div>

                <div className="flex flex-col items-center text-center  mx-auto h-[100%] w-[100%]">
                  <h2
                    className="font-['PPRightGrotesk-Bold'] text-[18px] font-black leading-tight text-center  whitespace-nowrap !text-primary-purple pb-[8px]"
                  >
                    {'Laptop recording'}
                  </h2>
                  <p className="font-inter text-[14px] leading-[24px] tracking-wide text-left !text-base-gray pb-[8px] text-center">
                    Basic - Free <br />
                    Premium - $15/mth
                  </p>
                  <a href="/pricing" className="font-inter text-[14px] leading-[24px] tracking-wide text-left pb-[16px] text-center underline" style={{ color: "#575F9E" }}>
                    Pricing
                  </a>
                  <div className="w-[100%]">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getDensity}
                      sx={{
                        width: "100%",
                        height: {
                          md: 44,
                          xs: 36,
                        },
                        margin: 0,
                        padding: {
                          xs: "10px 16px",
                          md: "15px",
                        },
                        backgroundColor: "#c1c5dd",
                        mb: "16px",
                        fontSize: {
                          md: "13px",
                        },
                      }}
                    >
                      download
                    </Button>
                  </div>
                  <img src={LaptopRecording} alt="with density hardware" className="w-[186px] pt-[16px]" />
                </div>
                <div>

                </div>
              </div>
              {/* <InfoCardWithAction
                data={{ ...getPurchaseHardwareCardInfo(), handleElementAction: handlePurchaseAction }}
              ></InfoCardWithAction> */}
            </div>
          </section>

          <section className="mt-[38px] md:mt-24">
            <StyledLandingVideoWrapper ref={videoRef}>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="Ix7_3nIACpU"
                youtube={{
                  autoplay: 1,
                  showinfo: 0,
                  controls: 1,
                }}
                onClose={() => setOpen(false)}
              />
              <img className="landing-video-image" src={NewCoverImage} alt="" />

              <button
                className="landing-video-play-icon"
                onClick={() => setOpen(true)}
              >
                <img
                  role="presentation"
                  src={PlayIcon}
                  onClick={() => setOpen(true)}
                  alt=""
                />
              </button>
              <StyledLandingBoldParagraph
              >
                {"Density explained in 90 seconds"}
              </StyledLandingBoldParagraph>
            </StyledLandingVideoWrapper>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoPanel data={{ ...TEXTS.FIRST_INFO_PANEL, descriptionLink: { title: "Download now", url: "/onboarding/1/density38", action: getDensity } }}>
              <img src={FullSetup} alt="the full setup" />
            </InfoPanel>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FIRST_INFO_SHOWCASE }}>
              <div className="pt-[4.7rem] flex justify-end">
                <img src={FindYourTracks} alt="find your tracks" className={'w-[683px]'} />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.SECOND_INFO_SHOWCASE, rtl: true }}>
              <div className="p-6">
                <img src={RemoveUnreleasedTrack} alt="remove unreleased tracks" className={'w-[683px]'} />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.THIRD_INFO_SHOWCASE, shadow: true }}>
              <div className="pt-[4.7rem] flex justify-end">
                <img src={NeverLoseASetAgain} alt="never lose a set again" className={'w-[683px]'} />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FOURTH_INFO_SHOWCASE, rtl: true }}>
              <div>
                <div className="m-8">
                  <div className="py-2 px-4 border border-[#FFFFFF1A] rounded-[45px] w-[140px] text-[Inter] text-[10px] leading-[23px] text-center">
                    {"LOUDNESS METER"}
                  </div>
                </div>
                <img src={EnsureConsistentLoudness} alt="ensure consistent loudness" />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FIFTH_INFO_SHOWCASE }}>
              <div className="py-5 pr-11 pb-11">
                <img src={RiderFriendly} alt="ensure consistent loudness" />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <div className="bg-gradient-to-b from-[#686FEF] to-transparent flex rounded-[30px]">
              <div className="flex flex-col md:flex-row w-[100%] m-auto">
                <div className="mt-10 md:my-10 flex flex-col justify-center items-start m-auto">
                  <h3 className="font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px] text-center 900px:text-left max-w-[15ch] mb-4">Record and edit in the Density DAW</h3>
                  <div className="mx-auto 900px:m-0">
                    <StyledAuthButton
                      variant="contained"
                      color="primary"
                      onClick={handleLearnMore}
                      sx={{
                        width: 150,
                        height: 36,
                        backgroundColor: "#c1c5dd",
                        margin: 0,
                        padding: "6px",
                        fontSize: {
                          xs: "10px",
                          md: "11px",
                        },
                        lineHeight: "16px",
                        letterSpacing: "1.25px",
                        fontWeight: 600,
                        display: "flex",
                        alignSelf: "self-start"

                      }}
                    >
                      {"Learn More"}
                    </StyledAuthButton>
                  </div>
                </div>

                <div className="max-w-[692.63px] mt10 mt-[50px]" >
                  <img src={EditRecordingsSmall} alt="give it a try" className="w-[692.63px]" />
                </div>
              </div>
            </div>
          </section>
          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.SIXTH_INFO_SHOWCASE }} customClassName={"bg-primary-gradient"}>
              <div className="p-5">
                <img className="-translate-x-[24px]" src={HardwareDensity} alt="give it a try" width={600} />
              </div>
              <div className="flex">
                <StyledAuthButton
                  variant="contained"
                  color="primary"
                  onClick={handlePurchaseAction}
                  sx={{
                    width: 150,
                    height: 36,
                    backgroundColor: "#c1c5dd",
                    margin: { xs: 'auto', md: 0 },
                    padding: "6px",
                    fontSize: {
                      xs: "10px",
                      md: "11px",
                    },
                    lineHeight: "16px",
                    letterSpacing: "1.25px",
                    fontWeight: 600,
                    display: "flex"
                  }}
                >
                  {"Purchase"}
                </StyledAuthButton>
              </div>
            </InfoShowcase>
          </section>

          <Footer />
        </main>
      </StyledLandingPageWrapper>
    </>
  );
};

export default LandingPageAlt;
